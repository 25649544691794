import angularModule from 'LazyLoadedPanelBody/angularModule/scripts/lazy_loaded_panel_body_module';

angularModule.directive('lazyLoadedPanelBody', [
    '$injector',

    function factory($injector) {
        const $compile = $injector.get('$compile');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            compile(origElem) {
                // if we're inside an ng-repeat, then compile only gets
                // called once.  This origElem is the one from the
                // template.  It is shared between the link functions for
                // each elem within the ng-repeat

                const clonedOnce = $('<div></div>');
                clonedOnce.append(origElem.children().remove());

                return function linkLazyLoadedPanel(scope, elem) {
                    elem.addClass('panel-body');

                    const collapsible = elem.closest('.collapse');
                    scope.in = () => collapsible.hasClass('in');

                    const callback = () => {
                        // clone the element from the template
                        // and compile it against this scope
                        const clonedAgain = $('<div></div>');
                        clonedAgain.append(clonedOnce.children().clone());
                        $compile(clonedAgain)(scope);
                        elem.append(clonedAgain);
                        safeApply(scope);
                    };

                    if (scope.in()) {
                        callback();
                    } else {
                        $(collapsible).one('show.bs.collapse', callback);

                        scope.$on('$destroy', () => {
                            $(collapsible).off('show.bs.collapse', callback);
                        });
                    }
                };
            },
        };
    },
]);
