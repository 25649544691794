import 'SafeApply/angularModule';

export default angular.module('JsonFileInput', ['safeApply']);

/**
 * Handles the inputting and parsing of csv files via a file input html element
 */
angular.module('JsonFileInput').directive('jsonFileInput', [
    '$window',
    'safeApply',
    ($window, safeApply) => ({
        require: 'ngModel',

        link(scope, elem, attrs, ngModel) {
            const onChange = evt => {
                const f = evt.target.files[0];
                const reader = new $window.FileReader();
                reader.onload = (() => e => {
                    const filePayload = e.target.result;

                    const json = angular.fromJson(filePayload);

                    safeApply(scope, () => {
                        ngModel.$setViewValue(json);
                    });

                    scope.resetInput();
                })(f);
                reader.readAsText(f);
            };

            $(elem).on('change', onChange);

            scope.resetInput = () => {
                // see http://stackoverflow.com/questions/1703228/how-to-clear-file-input-with-javascript/1703250#1703250
                // for info on resetting a file input field.

                // this seems to work in Chrome, but may not be reliable
                elem[0].value = '';

                // just in case that didn't work, let's try something else
                // I've never seen this work, but the internet says it might.
                if (elem[0].value) {
                    elem[0].type = 'text';
                    elem[0].type = 'file';
                }
            };
        },
    }),
]);
